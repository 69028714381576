var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"md-12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"md-12"}},[_c('h1',{staticClass:"custom-header-title float-left pr-1 mb-0"},[_vm._v(" Create Workflow ")])])],1)],1)],1),_c('validation-observer',{ref:"workFlowCreateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Workflow Name*","label-for":"h-work-flow-name","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-work-flow-name","placeholder":"Name","state":(errors.length > 0 || _vm.nameValidation) ? false : null,"name":"name"},on:{"input":function($event){_vm.nameValidation == true ? _vm.nameValidation = false : null}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.nameValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.nameError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-9"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"h-event-description","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"description","vid":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"h-event-description","placeholder":"Description","state":(errors.length > 0 || _vm.descriptionValidation) ? false : null,"name":"description"},on:{"input":function($event){_vm.descriptionValidation == true ? _vm.descriptionValidation = false : null}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.descriptionValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.descriptionError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-work-flow-status","label-cols-md":"3","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-work-flow-status","label":"title","options":_vm.option,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Category","vid":"category","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category","label-for":"h-work-flow-category","label-cols-md":"3","state":(errors.length > 0 || _vm.categoryValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-work-flow-category","label":"name","placeholder":"Select Category","options":_vm.categoryOptions,"reduce":function (name) { return name._id; },"clearable":true},on:{"input":function($event){_vm.categoryValidation == true ? _vm.categoryValidation = false : null}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.categoryValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.categoryError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Approval Required for Frontend Booking?","label-for":"h-activity-type-approvalRequired","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"approvalRequired","vid":"approvalRequired","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"spaceBetweenRadio",attrs:{"id":"h-activity-type-approvalRequired","button-variant":"outline-primary","buttons":""},model:{value:(_vm.approvalRequired),callback:function ($$v) {_vm.approvalRequired=$$v},expression:"approvalRequired"}},_vm._l((_vm.approvalSelections),function(selection,sIndex){return _c('b-form-radio',{key:sIndex,attrs:{"value":selection.value}},[(_vm.approvalRequired === selection.value)?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon","size":"16"}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(selection.text))])],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.approvalRequiredInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.approvalRequiredInvalidMessage)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Show in Portal?","label-for":"h-activity-type-customerBookable","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"customerBookable","vid":"customerBookable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"spaceBetweenRadio",attrs:{"id":"h-activity-type-customerBookable","button-variant":"outline-primary","buttons":""},model:{value:(_vm.customerBookable),callback:function ($$v) {_vm.customerBookable=$$v},expression:"customerBookable"}},_vm._l((_vm.approvalSelections),function(selection,sIndex){return _c('b-form-radio',{key:sIndex,attrs:{"value":selection.value}},[(_vm.customerBookable === selection.value)?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon","size":"16"}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(selection.text))])],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.customerBookableInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.customerBookableInvalidMessage)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'operation-workflows-index', params: { type: 'all-work-flows' } }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Workflow")])],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }